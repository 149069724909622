.medicalPersonal {
  row-gap: 16px;
  .text {
    width: 155px;
  }
  .list {
    margin-left: 0;
    padding: 0;
    word-wrap: break-word;
  }
}

.medicalFamily {
  .text {
    width: 155px;
  }
  .list {
    margin-left: 30px;
    padding: 0;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
  }
}

.collapse-css {
  .ant-collapse-header {
    font-weight: 700;
    font-size: 16px;
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}

// .ant-row {
//   margin-bottom: 16px;
// }
