.Calender {
  a {
    //box-shadow: none !important;
  }
  font-family: Roboto;
  background-color: #FFF;

  &.workingDay {
    background-color: #5B7FF2;
  }

  tr {
    height: 56px;
    border: 1px solid #F0F0F0;
  }

  td {
    border: 1px solid #e8e8e8 !important;
  }

  .fc .fc-non-business {
    background: unset;
    background-color: rgba(241, 236, 236, 0.4);
  }


  .fc-timegrid-event-harness .fc-timegrid-event-harness-inset {
    height: 100%;
  }

  .fc-event-main {
    overflow: hidden;
    max-height: 53px;
  }
  tbody {
    .fc-event-main h5,
    p {
      margin: 0;
      text-overflow: ellipsis;
      height: 40%;
      white-space: nowrap;
      color: #3E97FF;
    }
  }

  .fc-scrollgrid-sync-inner {
    height: 48px;

    .fc-col-header-cell-cushion {
      max-height: 48px;

      p {
        margin: 0;
      }
    }
  }

  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 14px;
    font-weight: 400;
    padding-inline: 4px;
  }
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #FFF;
}

.fc-timegrid-today {
  background-color: #FFF;
}

.fc-v-event {
  background-color: #d1e6ff;
  border: none;
  padding: 0;
  height: 47px;
  margin-top: 5px;
}
.fc-timegrid-slot.fc-timegrid-slot-lane {
  background-color: rgba(238, 246, 255, 0.2);
}

/**
 * Fully expanded "now" indicator line
 * @see https://github.com/fullcalendar/fullcalendar/issues/4609#issuecomment-1010706978
 */

.fc-timeGridWeek-view .fc-timegrid-now-indicator-container {
  overflow: visible !important;
}
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line {
  width: calc(700% + 6px) !important;
}

.fc .fc-timegrid-now-indicator-line {
  border-style: dashed;
}
.fc-timeGridWeek-view {
  .fc-timegrid-now-indicator-container {
    .fc-timegrid-now-indicator-arrow {
      margin-top: -4.5px;
    }
    overflow: visible !important;
    .fc-timegrid-now-indicator-line {
      &::before,
      &::after {
        border-style: solid;
        content: "";
        width: 700%;
        border-width: 1px 0 0px;
        position: absolute;
        top: -1px;
        border-color: red !important;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }
  }
}
div:hover{
  scrollbar-color: var(--bs-scrollbar-color) transparent !important;
  scrollbar-width: thin;
}

div:not(:hover){
  scrollbar-color: var(--bs-scrollbar-color) transparent !important;
  scrollbar-width: thin;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.4rem;
  margin-top: 5px;
}
.fc--button {
  display: none !important;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-radius: 0.475rem;
}
