
.modal-custom {
  .header {
  }
}
.modalStyle .ant-modal-header{
  border-radius: 0;
  text-align: center;
  color: #0842D6;
}

.error-text {
  color: red;
}

p {
  margin-top: 20px;
}
