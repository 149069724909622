.week-bubble {
  border-radius: 50%;
  background-color: #0842D6;
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.inactive {
    background-color: #ABB0BC;
  }
}
