.calendar-title {
  /* Danh sách lịch hẹn */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  letter-spacing: 0.25px;

  /* Text/1 */
  color: #4d4d4d;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.actions {
  color: #0842d6;
}
