.Schedule {
  position: relative;
  height: calc(-200px + 100vh);
  overflow: auto;
  .create-btn {
    z-index: 2;
    position: absolute;
    right: 2px;
    top: 5px;
    font-size: 16px;
    width: 173px;
    height: 36px;
  }
  .remove-btn {
    z-index: 2;
    position: absolute;
    right: 185px;
    top: 5px;
    font-size: 16px;
    width: 173px;
    height: 36px;
  }
}
