.call-window {
  @apply tw-h-full tw-flex tw-flex-col #{!important};
  // gap: 1rem;

  .video-panel,
  .header-row {
    @apply tw-flex;
  }

  .header-row {
    @apply tw-justify-end tw-items-center;
    padding: 1rem;

    button {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }

    /* Rectangle 1022 */
    background: #4d4d4d;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .micOff {
      button {
        color: red;
      }
    }

    .muted {
      button {
        color: red;
      }
    }

    .leave {
      button {
        @apply tw-font-bold;
        background: red;
        color: white;
      }
    }
  }

  .info-panel {
    @apply tw-flex tw-flex-col;
    width: 15%;

    .control-row {
      @apply tw-grow;
      padding: 1rem;
      textarea {
        @apply tw-h-full #{!important};
        border: 0.5px solid #b6c3fe !important;
        border-radius: 10px;
        background: #ffffff;
      }
    }
  }

  .info-row {
    @apply tw-flex tw-flex-col;
    height: fit-content;
    gap: 1rem;
    padding: 1rem;
  }

  .main-row {
    @apply tw-flex tw-grow;

    .video-panel {
      @apply tw-relative tw-grow tw-justify-center tw-h-full;

      div {
        border: 1px solid green;
      }

      .remote-video {
        // @apply tw-w-full;

        width: 60% !important;
        // height: 300px !important;
      }

      .local-video {
        @apply tw-absolute tw-bottom-0;
        left: calc(100% - (100% - 60%) / 2);

        border: 1px solid yellow;
      }

      .debug {
        @apply tw-hidden tw-absolute tw-bottom-0 tw-left-0;
      }
    }
  }

  .avatar {
    width: 15rem;
    height: 15rem;

    img {
      height: 100%;
    }
  }
}
